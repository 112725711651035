import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/blogLayout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import Button from "../components/button"

import cardImage from "../../content/assets/og-card.png"

import '../styles/blog.css'

class Blog extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    const blogPath = `${__PATH_PREFIX__}/blog`

    return (
      <Layout location={this.props.location} title={siteTitle} contentMaxWidth={rhythm(32)} >
        <SEO title="All posts" />
        <Bio />
        <div style={{ margin: "20px 0 40px" }}>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const img = node.frontmatter.hero_image ? `${__PATH_PREFIX__}/${node.frontmatter.hero_image}` : cardImage
            return (
              <div class="blog-post-preview" key={node.fields.slug}>
                <div class="blog-post-preview-img"
                  style={{
                    backgroundImage: `url(${img})`
                  }}
                ></div>
                <div class="blog-post-preview-content">
                  <h3
                    style={{
                      marginBottom: rhythm(1 / 4),
                    }}
                  >
                    <Link
                      to={`${blogPath}${node.fields.slug}`}
                    >
                      {title}
                    </Link>
                  </h3>
                  <p style={{
                      marginTop: rhythm(1 / 4),
                      marginBottom: rhythm(1 / 4),
                    }}
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                  <small style={{
                      display: "block",
                      marginTop: rhythm(1 / 2),
                      marginBottom: rhythm(1 / 2),
                    }}
                  >
                    {node.frontmatter.date}
                  </small>
                </div>
              </div>
            )
          })}
        </div>
        <Link to="/">
          <Button marginTop="85px">Back to Homepage</Button>
        </Link>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { 
        posttype: { ne: "book-note" } 
        status: { eq: "published" }
      } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            hero_image
          }
        }
      }
    }
  }
`
